var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col" }, [
    _c("header", [_vm._t("header")], 2),
    _c(
      "div",
      { ref: "container", staticClass: "overflow-hidden overflow-y-auto" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }