var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 00 1120.592 777.916",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "212.592", cy: "103", r: "64", fill: "#ff6584" },
      }),
      _c("path", {
        attrs: {
          d: "M523.976 343.122c0 151.011-89.774 203.739-200.515\n                  203.739s-200.516-52.728-200.516-203.74S323.461 0 323.461 0s200.515 192.11 200.515 343.122z",
          fill: "#f2f2f2",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#3f3d56",
          d: "M316.156 523.761l2.054-126.383 85.464-156.354-85.142 136.528.923-56.827\n                  58.902-113.12-58.658 98.082 1.66-102.206 63.074-90.058-62.812 73.986L322.658 0l-6.52\n                  248.096.536-10.235-64.127-98.157 63.099 117.804-5.975 114.146-.178-3.029-73.928-103.296\n                  73.704 113.999-.747 14.275-.134.215.061 1.172-15.159 289.599h20.254l2.43-149.584\n                  73.522-113.72-73.34 102.476z",
        },
      }),
      _c("path", {
        attrs: {
          d: "M1120.592 404.972c0 123.61-73.484 166.77-164.131\n                  166.77s-164.132-43.16-164.132-166.77S956.461 124.11 956.461 124.11s164.131 157.252 164.131\n                  280.862z",
          fill: "#f2f2f2",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#3f3d56",
          d: "M950.482 552.833l1.68-103.45 69.957-127.983-69.693 111.754.756-46.515\n                  48.214-92.595-48.014 80.285 1.359-83.66 51.628-73.717-51.415 60.562.85-153.404-5.337\n                  203.078.439-8.377-52.492-80.347 51.65 96.429-4.891 93.434-.146-2.48-60.513-84.553 60.33 93.314-.612\n                  11.685-.109.176.05.959-12.409 237.05h16.579l1.989-122.441 60.182-93.085-60.032 83.881z",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "554.592",
          cy: "680.479",
          rx: "554.592",
          ry: "28.034",
          fill: "#3f3d56",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "892.445",
          cy: "726.797",
          rx: "94.989",
          ry: "4.802",
          fill: "#3f3d56",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "548.72",
          cy: "773.114",
          rx: "94.989",
          ry: "4.802",
          fill: "#3f3d56",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "287.944",
          cy: "734.279",
          rx: "217.014",
          ry: "10.97",
          fill: "#3f3d56",
        },
      }),
      _c("circle", {
        attrs: { cx: "97.084", cy: "566.27", r: "79", fill: "#2f2e41" },
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d: "M60.355 627.841l23.999.283-.507 42.997-23.998-.283zM108.352\n                  628.407l23.999.283-.507 42.997-23.998-.283z",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "119.546",
          cy: "732.616",
          rx: "7.5",
          ry: "20",
          transform: "rotate(-89.325 68.811 722.182)",
          fill: "#2f2e41",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "167.554",
          cy: "732.182",
          rx: "7.5",
          ry: "20",
          transform: "rotate(-89.325 116.82 721.748)",
          fill: "#2f2e41",
        },
      }),
      _c("circle", {
        attrs: { cx: "99.319", cy: "546.295", r: "27", fill: "#fff" },
      }),
      _c("circle", {
        attrs: { cx: "99.319", cy: "546.295", r: "9", fill: "#3f3d56" },
      }),
      _c("path", {
        attrs: {
          d: "M21.322 491.904c-6.042-28.64 14.688-57.265 46.3-63.933s62.139 11.143 68.18\n                  39.783-14.978 38.93-46.59 45.6-61.848 7.191-67.89-21.45z",
          fill: "#ed8936",
        },
      }),
      _c("path", {
        attrs: {
          d: "M217.592 610.342c0 55.076-32.74 74.306-73.13 74.306q-1.403\n                  0-2.802-.03c-1.872-.041-3.725-.13-5.556-.255-36.452-2.58-64.772-22.8-64.772-74.021 0-53.008\n                  67.739-119.896 72.827-124.846l.01-.01.293-.284s73.13 70.064 73.13 125.14z",
          fill: "#ed8936",
        },
      }),
      _c("path", {
        attrs: {
          d: "M141.798 676.223l26.747-37.374-26.814 41.477-.071\n                  4.291c-1.872-.04-3.725-.13-5.556-.254l2.882-55.102-.022-.428.049-.08.272-5.205-26.88-41.58 26.964\n                  37.677.063 1.105 2.179-41.633-23.014-42.965 23.294 35.658 2.268-86.314.01-.294v.285l-.38\n                  68.064 22.911-26.983-23.004 32.846-.606 37.276 21.391-35.774-21.48 41.259-.339 20.723\n                  31.056-49.792-31.172 57.023z",
          fill: "#3f3d56",
        },
      }),
      _c("circle", {
        attrs: { cx: "712.485", cy: "565.415", r: "79", fill: "#2f2e41" },
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d: "M696.287 635.256l22.94-7.05 12.633 41.102-22.941 7.05zM742.168\n                  621.16l22.94-7.05 12.632 41.102-22.94 7.05z",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "767.887",
          cy: "732.003",
          rx: "20",
          ry: "7.5",
          transform: "rotate(-17.083 544.826 833.656)",
          fill: "#2f2e41",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "813.475",
          cy: "716.946",
          rx: "20",
          ry: "7.5",
          transform: "rotate(-17.083 590.415 818.599)",
          fill: "#2f2e41",
        },
      }),
      _c("circle", {
        attrs: { cx: "708.522", cy: "545.71", r: "27", fill: "#fff" },
      }),
      _c("circle", {
        attrs: { cx: "708.522", cy: "545.71", r: "9", fill: "#3f3d56" },
      }),
      _c("path", {
        attrs: {
          d: "M617.651 517.701c-14.49-25.433-3.478-59.016 24.595-75.01s62.575-8.34 77.065 17.094-2.39\n                  41.643-30.463 57.636-56.707 25.713-71.197.28zM571.592 600.257c0 50.557-30.053 68.21-67.13\n                  68.21q-1.288 0-2.572-.03a109.57 109.57 0 01-5.1-.232c-33.461-2.368-59.458-20.93-59.458-67.948\n                  0-48.66 62.181-110.06 66.852-114.604l.008-.008c.18-.176.27-.261.27-.261s67.13 64.316 67.13\n                  114.873z",
          fill: "#ed8936",
        },
      }),
      _c("path", {
        attrs: {
          d: "M502.016 660.732l24.553-34.307-24.614 38.074-.065 3.939a109.57 109.57 0\n                  01-5.1-.233l2.646-50.582-.02-.393.044-.073.25-4.777-24.676-38.169 24.753 34.586.058 1.014\n                  2-38.217-21.125-39.44 21.382 32.732 2.082-79.233.008-.27v.262l-.347 62.48 21.03-24.77-21.116\n                  30.152-.557 34.218 19.637-32.84-19.718 37.875-.311 19.022 28.507-45.706-28.614 52.345z",
          fill: "#3f3d56",
        },
      }),
      _c("path", {
        attrs: {
          d: "M835.592 621.342c0 55.076-32.74 74.306-73.13 74.306q-1.403\n                  0-2.802-.03c-1.872-.041-3.725-.13-5.556-.255-36.452-2.58-64.772-22.8-64.772-74.021\n                  0-53.008 67.739-119.896 72.827-124.846l.01-.01.293-.284s73.13 70.064 73.13 125.14z",
          fill: "#ed8936",
        },
      }),
      _c("path", {
        attrs: {
          d: "M759.798 687.223l26.747-37.374-26.814 41.477-.071\n                  4.291c-1.872-.04-3.725-.13-5.556-.254l2.882-55.102-.022-.428.049-.08.272-5.205-26.88-41.58 26.965\n                  37.677.062 1.105 2.179-41.633-23.014-42.965 23.294 35.658 2.268-86.314.01-.294v.285l-.38 68.064\n                  22.911-26.983-23.004 32.846-.606 37.276 21.391-35.774-21.48 41.259-.339 20.723\n                  31.056-49.792-31.172 57.023z",
          fill: "#3f3d56",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "721.517",
          cy: "656.822",
          rx: "12.4",
          ry: "39.5",
          transform: "rotate(-64.626 653.41 657.688)",
          fill: "#2f2e41",
        },
      }),
      _c("ellipse", {
        attrs: {
          cx: "112.517",
          cy: "651.822",
          rx: "12.4",
          ry: "39.5",
          transform: "rotate(-68.158 47.55 650.645)",
          fill: "#2f2e41",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }