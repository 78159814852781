<template>
  <div class="flex flex-col">
    <header>
      <slot name="header"></slot>
    </header>
    <div ref="container" class="overflow-hidden overflow-y-auto">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FixedHeaderContainer',
};
</script>
