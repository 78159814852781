<template>
  <fixed-header-container class="overflow-hidden">
    <div v-if="isLoading">
      <line-loader v-for="line in totalLinesLoading" :key="line" class="rounded mb-2" />
    </div>
    <div v-else-if="options.length" id="contents-select" class="flex flex-col w-full overflow-y-auto">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="flex justify-between items-center cursor-default rounded px-4 py-2 hover:bg-gray-200"
      >
        <div class="text-sm text-gray-700">
          <slot name="option" :option="option">
            {{ option.name }}
          </slot>
        </div>
        <div class="flex">
          <!-- Click event does not work on sun-popover-->
          <div
            :class="{ 'pointer-events-none': !multiple && !isAlreadyInclude(option) && includes.length > 0 }"
            @click="onIncludePick(option)"
          >
            <sun-popover
              class="mr-4"
              :class="{
                'text-green-700 hover:text-green-500': isAlreadyInclude(option),
                'cursor-not-allowed text-gray-300': !canInclude,
                'hover:text-gray-400 cursor-pointer text-gray-500': canInclude,
              }"
              :trigger="!canInclude ? 'hover click' : 'null'"
              :text-info="notAllowedText"
            >
              <check-svg class="w-4 m-2"></check-svg>
            </sun-popover>
          </div>
          <div
            v-if="isExcludeVisible"
            :class="{ 'pointer-events-none': !multiple && !isAlreadyExclude(option) && excludes.length > 0 }"
            @click="onExcludePick(option)"
          >
            <sun-popover
              class="mr-4"
              :class="{
                'text-red-700 hover:text-red-500': isAlreadyExclude(option),
                'cursor-not-allowed text-gray-300': !canExclude,
                'hover:text-gray-400 cursor-pointer text-gray-500': canExclude,
              }"
              :trigger="!canExclude ? 'hover click' : 'null'"
              :text-info="notAllowedText"
            >
              <exclude-svg class="w-4 m-2"></exclude-svg>
            </sun-popover>
          </div>
        </div>
      </div>
    </div>
    <asterix-no-data v-else title="No data found" subtitle="Try again using different keywords" class="text-center" />
  </fixed-header-container>
</template>
<script>
import FixedHeaderContainer from '@/components/molecules/shared/FixedHeaderContainer';
import AsterixNoData from '@/components/organisms/shared/AsterixNoData';
import CheckSvg from '@/components/icons/CheckSvg';
import ExcludeSvg from '@/components/icons/ExcludeSvg';
import LineLoader from '@/components/atoms/Loaders/LineLoader.vue';

export default {
  name: 'TargetOptionsList',
  components: {
    FixedHeaderContainer,
    AsterixNoData,
    CheckSvg,
    ExcludeSvg,
    LineLoader,
  },
  props: {
    canInclude: {
      type: Boolean,
      default: false,
    },
    canExclude: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    includes: {
      type: Array,
      required: true,
    },
    excludes: {
      type: Array,
      required: true,
    },
    isExcludeVisible: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    totalLinesLoading: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      notAllowedText: 'You can not include and exclude items at the same time',
    };
  },
  methods: {
    onIncludePick(option) {
      this.$emit('include', option);
    },
    onExcludePick(option) {
      this.$emit('exclude', option);
    },
    isAlreadyInclude(option) {
      return this.includes.find(item => item.id === option.id);
    },
    isAlreadyExclude(option) {
      return this.excludes.find(item => item.id === option.id);
    },
  },
};
</script>
