var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fixed-header-container",
    { staticClass: "overflow-hidden" },
    [
      _vm.isLoading
        ? _c(
            "div",
            _vm._l(_vm.totalLinesLoading, function (line) {
              return _c("line-loader", {
                key: line,
                staticClass: "rounded mb-2",
              })
            }),
            1
          )
        : _vm.options.length
        ? _c(
            "div",
            {
              staticClass: "flex flex-col w-full overflow-y-auto",
              attrs: { id: "contents-select" },
            },
            _vm._l(_vm.options, function (option, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "flex justify-between items-center cursor-default rounded px-4 py-2 hover:bg-gray-200",
                },
                [
                  _c(
                    "div",
                    { staticClass: "text-sm text-gray-700" },
                    [
                      _vm._t(
                        "option",
                        function () {
                          return [_vm._v(" " + _vm._s(option.name) + " ")]
                        },
                        { option: option }
                      ),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        class: {
                          "pointer-events-none":
                            !_vm.multiple &&
                            !_vm.isAlreadyInclude(option) &&
                            _vm.includes.length > 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onIncludePick(option)
                          },
                        },
                      },
                      [
                        _c(
                          "sun-popover",
                          {
                            staticClass: "mr-4",
                            class: {
                              "text-green-700 hover:text-green-500":
                                _vm.isAlreadyInclude(option),
                              "cursor-not-allowed text-gray-300":
                                !_vm.canInclude,
                              "hover:text-gray-400 cursor-pointer text-gray-500":
                                _vm.canInclude,
                            },
                            attrs: {
                              trigger: !_vm.canInclude ? "hover click" : "null",
                              "text-info": _vm.notAllowedText,
                            },
                          },
                          [_c("check-svg", { staticClass: "w-4 m-2" })],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isExcludeVisible
                      ? _c(
                          "div",
                          {
                            class: {
                              "pointer-events-none":
                                !_vm.multiple &&
                                !_vm.isAlreadyExclude(option) &&
                                _vm.excludes.length > 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onExcludePick(option)
                              },
                            },
                          },
                          [
                            _c(
                              "sun-popover",
                              {
                                staticClass: "mr-4",
                                class: {
                                  "text-red-700 hover:text-red-500":
                                    _vm.isAlreadyExclude(option),
                                  "cursor-not-allowed text-gray-300":
                                    !_vm.canExclude,
                                  "hover:text-gray-400 cursor-pointer text-gray-500":
                                    _vm.canExclude,
                                },
                                attrs: {
                                  trigger: !_vm.canExclude
                                    ? "hover click"
                                    : "null",
                                  "text-info": _vm.notAllowedText,
                                },
                              },
                              [_c("exclude-svg", { staticClass: "w-4 m-2" })],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          )
        : _c("asterix-no-data", {
            staticClass: "text-center",
            attrs: {
              title: "No data found",
              subtitle: "Try again using different keywords",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }